/**
 * @prettier
 */

import attempt from 'lodash/attempt'
import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import ceil from 'lodash/ceil'
import chain from 'lodash/chain'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import debounce from 'lodash/debounce'
import delay from 'lodash/delay'
import difference from 'lodash/difference'
import differenceBy from 'lodash/differenceBy'
import endsWith from 'lodash/endsWith'
import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import flatten from 'lodash/flatten'
import flow from 'lodash/flow'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import identity from 'lodash/identity'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isError from 'lodash/isError'
import isFinite from 'lodash/isFinite'
import isFunction from 'lodash/isFunction'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import join from 'lodash/join'
import kebabCase from 'lodash/kebabCase'
import keys from 'lodash/keys'
import last from 'lodash/last'
import lowerCase from 'lodash/lowerCase'
import lowerFirst from 'lodash/lowerFirst'
import map from 'lodash/map'
import max from 'lodash/max'
import merge from 'lodash/merge'
import method from 'lodash/method'
import minBy from 'lodash/minBy'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import padStart from 'lodash/padStart'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import range from 'lodash/range'
import reduce from 'lodash/reduce'
import reject from 'lodash/reject'
import remove from 'lodash/remove'
import round from 'lodash/round'
import set from 'lodash/set'
import size from 'lodash/size'
import slice from 'lodash/slice'
import snakeCase from 'lodash/snakeCase'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import split from 'lodash/split'
import startCase from 'lodash/startCase'
import startsWith from 'lodash/startsWith'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import tap from 'lodash/tap'
import thru from 'lodash/thru'
import times from 'lodash/times'
import toLower from 'lodash/toLower'
import transform from 'lodash/transform'
import trim from 'lodash/trim'
import trimEnd from 'lodash/trimEnd'
import truncate from 'lodash/truncate'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import upperCase from 'lodash/upperCase'
import upperFirst from 'lodash/upperFirst'
import values from 'lodash/values'
import without from 'lodash/without'
import mapValues from 'lodash/mapValues'
import forOwn from 'lodash/forOwn'
import constant from 'lodash/constant'
import update from 'lodash/update'
import each from 'lodash/each'
import toArray from 'lodash/toArray'
import union from 'lodash/union'
import flatMap from 'lodash/flatMap'
import head from 'lodash/head'
import assign from 'lodash/assign'
import groupBy from 'lodash/groupBy'
import toInteger from 'lodash/toInteger'
import toUpper from 'lodash/toUpper'
import matchesProperty from 'lodash/matchesProperty'
import has from 'lodash/has'
import unset from 'lodash/unset'
import value from 'lodash/value'
import mapKeys from 'lodash/mapKeys'
import clamp from 'lodash/clamp'

export const lodashModules = {
  mapKeys,
  value,
  reject,
  assign,
  attempt,
  camelCase,
  capitalize,
  ceil,
  chain,
  clone,
  clamp,
  cloneDeep,
  compact,
  concat,
  constant,
  debounce,
  delay,
  difference,
  differenceBy,
  each,
  endsWith,
  every,
  filter,
  find,
  findIndex,
  flatMap,
  flatten,
  flow,
  forEach,
  forOwn,
  get,
  groupBy,
  has,
  head,
  identity,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isError,
  isFinite,
  isFunction,
  isNaN,
  isNil,
  isNull,
  isNumber,
  isObject,
  isPlainObject,
  isString,
  isUndefined,
  join,
  kebabCase,
  keys,
  last,
  lowerCase,
  lowerFirst,
  map,
  mapValues,
  matchesProperty,
  max,
  merge,
  method,
  minBy,
  omit,
  orderBy,
  padStart,
  pick,
  pickBy,
  range,
  reduce,
  remove,
  round,
  set,
  size,
  slice,
  snakeCase,
  some,
  sortBy,
  split,
  startCase,
  startsWith,
  sum,
  sumBy,
  tap,
  thru,
  times,
  toArray,
  toInteger,
  toLower,
  toUpper,
  transform,
  trim,
  trimEnd,
  truncate,
  union,
  uniq,
  uniqBy,
  unset,
  update,
  upperCase,
  upperFirst,
  values,
  without,
}
