/**
 * @prettier
 */

;(function (Quotes, $) {
  let initQuotes
  const self = Quotes

  self.init = () => initQuotes()

  initQuotes = () =>
    $('.js-slick').slick({
      dots: true,
      appendDots: '.js-rating-quotes-dots-container',
      arrows: false,
    })
})((window.Quotes = window.Quotes || {}), jQuery)
