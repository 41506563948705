/**
 * @prettier
 */

;(function (CustomerServicePortal, $) {
  const self = CustomerServicePortal

  const showContent = function () {
    $('.js-app').addClass('is-shown')
    setTimeout(() => {
      $('.js-header').removeClass('is-hidden')
    }, 2000)
    $('.js-hero-slide').eq(0).addClass('is-active')
    $('.js-hero-decor').eq(0).addClass('is-active')
  }

  self.init = () => showContent()
})((window.CustomerServicePortal = window.CustomerServicePortal || {}), jQuery)
