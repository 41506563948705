/**
 * @prettier
 */

;(function (Jobs, $) {
  const self = Jobs
  const VIDEO_TEASER = 'nPn2B5UykQk'
  const VIDEO_FULL_FOOTAGE = '0wNws02ybSM'
  const playerConfig = {
    controls: 0,
    showinfo: 0,
    cc_load_policy: 0,
    iv_load_policy: 0,
    autohide: 1,
    loop: 1,
    rel: 0,
    playsinline: 1, // shows video inline instead of fullscreen, for mobile
  }

  const playTeaser = function (player) {
    if (!player) {
      player = this.YTplayer
    }
    player.loadVideoById(VIDEO_TEASER)
    player.playVideo()
  }

  const stopFullFootage = function (player) {
    if (!player) {
      player = this.YTplayer
    }
    player.stopVideo()
    playTeaser()
    $(document).off('keyup.resize_iframe')
    $('.js-yt-player-overlay').removeClass('is-active')
    $('.js-yt-player-close-button').hide(500)
    $('.js-yt-player').fadeOut(500)
    setTimeout(() => {
      $('.js-jobs-video-holder').removeClass('is-active').height('').width('')
      $('.js-header').removeClass('is-wide is-barebone')
    }, 500)
    setTimeout(() => $('.js-jobs-headline').fadeIn(700), 700)
  }

  const initPlayer = function () {
    integrateYTlib()
    this.onYouTubeIframeAPIReady = function () {
      const params = {
        playerVars: playerConfig,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      }

      this.YTplayer = new YT.Player('yt-player-iframe', params)
    }
  }

  const integrateYTlib = function () {
    const tag = document.createElement('script')

    tag.src = 'https://www.youtube.com/iframe_api'
    const playerHolder = document.getElementById('yt-player-iframe')

    playerHolder.appendChild(tag)
  }

  const resizeYTIframe = function (fullscreen = false) {
    $('.js-yt-player')
      .height($(window).width() * 0.5625)
      .width($(window).width())

    if (fullscreen) {
      $('.js-jobs-video-holder')
        .height($(window).width() * 0.5625)
        .width($(window).width())
      $('.js-jobs-video-holder').addClass('is-active')
    }
  }

  const onPlayerReady = function (event) {
    $('.js-yt-player-play-button').removeClass('is-disabled').addClass('animated tada')
    playTeaser(event.target)
    resizeYTIframe()
    $('.js-yt-player-play-button').on('click', () => playFullFootage(event.target))
    $('.js-yt-player-close-button').on('click', () => stopFullFootage())
  }

  const onPlayerStateChange = function (event) {
    if (event.data === YT.PlayerState.PLAYING) {
      $('.js-yt-player-play-button').removeClass('animated tada')
      $('.js-yt-player').show()
      this.YTplayer.setVolume(100)
      if (this.YTplayer.getVideoData().video_id === VIDEO_FULL_FOOTAGE) {
        $(window).on('resize', resizeYTIframe)
        $(document).on('keyup.resize_iframe', function (event) {
          if (event.keyCode === 27) {
            stopFullFootage()
          }
        })
      }
    } else if (event.data === YT.PlayerState.ENDED) {
      if (this.YTplayer.getVideoData().video_id === VIDEO_TEASER) {
        this.YTplayer.seekTo(0)
      }
      if (this.YTplayer.getVideoData().video_id === VIDEO_FULL_FOOTAGE) {
        stopFullFootage()
      }
      $(window).off('resize', resizeYTIframe)
    } else if (event.data === YT.PlayerState.UNSTARTED) {
      $(window).off('resize', resizeYTIframe)
    }
  }

  const playFullFootage = function (player) {
    if (!player) {
      player = this.YTplayer
    }
    player.loadVideoById(VIDEO_FULL_FOOTAGE)
    $('.js-jobs-headline').fadeOut(500)
    $('.js-header').addClass('is-wide is-barebone')
    $('.js-yt-player-overlay').addClass('is-active')
    $('.js-yt-player-close-button').show(500)
    resizeYTIframe(true)
    player.playVideo()
    player.seekTo(0)
  }

  self.init = function () {
    // https://developers.google.com/youtube/iframe_api_reference#Mobile_considerations
    // Due to this restriction, functions and parameters
    // such as autoplay, playVideo(), loadVideoById() won't work
    // in all mobile environments.
    if (!Utils.isMobile.any()) {
      this.YTplayer = null
      initPlayer()
      $(window).resize(() => resizeYTIframe())
    }

    if (Utils.isMobile.any()) {
      $('.js-jobs-video-holder').addClass('is-mobile')
    }
  }
})((window.Jobs = window.Jobs || {}), jQuery)
