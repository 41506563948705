/**
 * @prettier
 */

;(function (PhotoTooltip, $) {
  const self = PhotoTooltip

  self.init = function () {
    const image = $('.js-img-container')
    const tooltip = $('.js-photo-tooltip')
    const tooltipClose = $('.js-photo-tooltip-content-close, .js-overlay')
    const overlay = $('.js-overlay')
    const $window = $(window)

    const checkWidth = function () {
      const windowsize = $window.width()

      function mousemoveOnImage(e) {
        tooltip.css({
          top: e.clientY + 50 + 'px',
          left: e.clientX + 50 + 'px',
        })
      }
      function mouseoverOnImage() {
        if (
          $(this).data('trigger') &&
          $(this).data('trigger') === $(this).find(tooltip).data('item')
        ) {
          $(`.js-photo-tooltip[data-item='${$(this).data('trigger')}'`).show()
        }
      }
      function mouseoutOnImage() {
        if (
          $(this).data('trigger') &&
          $(this).data('trigger') === $(this).find(tooltip).data('item')
        ) {
          $(`.js-photo-tooltip[data-item='${$(this).data('trigger')}'`).hide()
        }
      }
      function clickToTooltip(e) {
        e.stopImmediatePropagation()
        tooltip.hide()
        overlay.hide()
      }
      function clickToImg() {
        if (
          $(this).data('trigger') &&
          $(this).data('trigger') === $(this).find(tooltip).data('item')
        ) {
          $(`.js-photo-tooltip[data-item='${$(this).data('trigger')}'`).show()
          overlay.show()
        }
      }

      tooltip.hide()
      overlay.hide()
      tooltip.css({
        top: '',
        left: '',
      })

      if (windowsize > 1367) {
        tooltipClose.unbind('click')
        image.unbind('click')
        image.on('mousemove', mousemoveOnImage)
        image.on('mouseover', mouseoverOnImage)
        image.on('mouseout', mouseoutOnImage)
      } else {
        image.unbind('mousemove')
        image.unbind('mouseover')
        image.unbind('mouseout')
        tooltipClose.on('click', clickToTooltip)
        image.on('click', clickToImg)
      }
    }

    checkWidth()
    $(window).resize(checkWidth)
  }
})((window.PhotoTooltip = window.PhotoTooltip || {}), jQuery)
