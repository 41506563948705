/**
 * @prettier
 */

;(function (Spinner, $) {
  const self = Spinner

  self.init = () =>
    $(document).on({
      ajaxStart() {
        self.start()
      },

      ajaxStop() {
        self.stop()
      },
    })

  self.element = $('.js-spinner')

  self.start = () => self.element.addClass('is-shown')

  self.stop = () => self.element.removeClass('is-shown')
})((window.Spinner = window.Spinner || {}), jQuery)
