/**
 * @prettier
 */

global.jQuery = require('jquery')

window.$ = jQuery

require('vendor/javascripts/jquery/jquery-migrate-3.3.2')
require('vendor/javascripts/jquery-plugins/jquery.lazy.min')
require('vendor/javascripts/rails')
require('vendor/javascripts/slick.min')
require('./widget')

$(function () {
  Utils.init()
  Utils.trackGAClicks()
  $('[data-widget]').each(function (i, e) {
    let widget = $(e).data('widget')

    if (window[widget]) window[widget].init()
  })

  $('.js-lazy').lazy({
    beforeLoad(element) {
      if (element.hasClass('js-container-first-image')) {
        $('.js-lazy-container').lazy({
          bind: 'event',
          delay: 0,
        })
      }
    },
  })
})
