/**
 * @prettier
 */

;(function (Modal, $) {
  const self = Modal
  const element = '.js-modal'
  const widget = `${element}-widget`
  const content = `${element}-content`
  const close = `${element}-close`
  const overlay = `${element}-overlay`
  const title = `${element}-title`
  const template = `
    <div class='js-modal-close js-modal-overlay modal-overlay'></div>
    <div class='js-modal modal'>
      <div class='js-modal-close modal-close'></div>
      <div class='js-modal-title modal-title'></div>
      <div class='js-modal-content modal-content'></div>
      <div class='js-modal-close button'>OK</div>
    </div>
`

  self.init = function () {
    if (!$(element).length) {
      $(widget).append(template)
    }
    $(close).on('click.modal_close', self.hide)
    $(document).on('keyup.modal_close', function (e) {
      if (e.keyCode === 27) {
        self.hide()
      }
    })
  }

  self.show = function (data, state) {
    self.write(data)
    $(`${element}, ${overlay}`).addClass('is-shown')
  }

  self.hide = function () {
    self.write()
    $(document).off('keyup.confirmation')
    $(`${element}, ${overlay}`).removeClass('is-shown')
  }

  self.write = function (data = {}) {
    if (!_.isObject(data)) {
      return
    }
    $(title).html(data.title)
    $(content).html(data.content)
  }
})((window.Modal = window.Modal || {}), jQuery)
