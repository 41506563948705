/**
 * @prettier
 */

;(function (Subscription, $) {
  const self = Subscription

  const showContent = function () {
    initQuotes()
    initSlider()
    $('.js-app').addClass('is-shown')
    setTimeout(() => $('.js-header').removeClass('is-hidden'), 2000)
    $('.js-hero-slide').eq(0).addClass('is-active')
    $('.js-hero-decor').eq(0).addClass('is-active')
  }

  const initQuotes = function () {
    $('.js-quote-trigger').eq(0).addClass('is-active')
    $('.js-quote').eq(0).addClass('is-active')

    $('.js-quote-trigger').on('click', function () {
      const index = $('.js-quote-trigger').index(this)

      $('.js-quote-trigger, .js-quote').removeClass('is-active')
      $('.js-quote-trigger').eq(index).addClass('is-active')
      $('.js-quote').eq(index).addClass('is-active')
    })
  }

  const initSlider = function () {
    let n = 1
    let stopSlider = false
    const updateSlide = function () {
      $('.js-slider-list').removeClass('active')
      $('.js-slider-img').removeClass('active')
      $('.js-slider-text').removeClass('active')
      $('.js-slider-list').eq(n).addClass('active')
      $('.js-slider-img').eq(n).addClass('active')
      $('.js-slider-text').eq(n).addClass('active')

      if (n === $('.js-slider-list').length - 1) {
        n = 0
      } else {
        n++
      }
    }

    let intervalNumber = setInterval(() => {
      updateSlide()
    }, 5000)

    $('.js-slider-list').on('click', function () {
      stopSlider = true
      clearInterval(intervalNumber)
      n = $('.js-slider-list').index($(this))
      updateSlide()
    })

    $('.js-slider-img').mouseenter(() => {
      clearInterval(intervalNumber)
    })

    $('.js-slider-img').mouseleave(function () {
      if (!stopSlider) {
        intervalNumber = setInterval(() => {
          updateSlide()
        }, 5000)
      }
    })
  }

  self.init = () => {
    showContent()
  }
})((window.Subscription = window.Subscription || {}), jQuery)
