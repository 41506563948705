/**
 * @prettier
 */

;(function (Apply, $) {
  const self = Apply

  self.selector = $('.js-form')

  const customSuccessMessage = $('.js-form-validation-success').text()
  const defaultErrorMessage = 'Something went wrong'

  jQuery.extend({
    getQueryParameters(str) {
      return (str || document.location.search)
        .replace(/(^\?)/, '')
        .split('&')
        .map(
          function (n) {
            n = n.split('=')
            this[n[0]] = n[1]

            return this
          }.bind({}),
        )[0]
    },
  })

  self.getUrlParameters = function () {
    if (window.location.search) {
      return $.getQueryParameters(window.location.search)
    }
  }

  self.businessEmailValidation = function () {
    const getErrorMessage = function (value) {
      const standardEmail = /^.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+$/gi
      const BusinessEmailErrorMessage = 'Please provide your work email'
      const isStandardEmail = Boolean(standardEmail.test(value))

      if (isStandardEmail) {
        return BusinessEmailErrorMessage
      } else if (value === '') {
        return 'Please fill in this field'
      } else {
        return ''
      }
    }

    document.querySelector('input[type=email]').addEventListener('input', function (e) {
      const errorMessage = getErrorMessage(e.target.value)

      e.target.setCustomValidity(errorMessage)
    })
  }

  self.trackGoogleConversion = function () {
    if (self.selector.data('remarketing')) {
      const data = {
        google_conversion_id: 964_877_505,
        google_conversion_language: 'en',
        google_conversion_format: '3',
        google_conversion_color: 'ffffff',
        google_remarketing_only: false,
      }

      data.google_conversion_label = (() => {
        switch (self.selector.data('remarketing')) {
          case 'homepage':
            return 'Tjz3CO_J13IQwbmLzAM'
          case 'contact':
            return 'wd6kCIy-xXIQwbmLzAM'
          case 'handbook':
            return 'FMVGCOGa3nIQwbmLzAM'
        }
      })()

      if (typeof window.google_trackConversion === 'function') {
        window.google_trackConversion(data)
      }
    }
  }

  self.trackCapterra = function () {
    const capterra_vkey = 'fef905578ee94c3f5c8f2bf33f68ce27'
    const capterra_vid = '2107239'
    const src = `https://ct.capterra.com/capterra_tracker.js?vid=${capterra_vid}&vkey=${capterra_vkey}`

    $('body').append(`<script async src="${src}"></script>`)
  }

  self.trackSubmission = function (message) {
    Utils.trackGAEvent(
      'Sales Inquiry Forms',
      'Submit',
      self.selector.data('ga-event-label'),
    )

    Modal.show({ title: 'Thank you', content: customSuccessMessage || message })
    self.selector.trigger('reset')
    self.trackGoogleConversion()
  }

  self.init = function () {
    self.businessEmailValidation()

    self.selector.on({
      submit(e) {
        e && e.preventDefault()
        const salesforceInputElem = document.querySelector('.js-google-salesforce')

        if (window.ga && salesforceInputElem) {
          const clientId = ga.getAll()[0] && ga.getAll()[0].get('clientId')

          if (clientId) {
            salesforceInputElem.value = clientId
          } else {
            window.Bugsnag &&
              Bugsnag.notify({
                name: 'Sales Inquiry Forms',
                message: 'submit without customer ID',
              })
          }
        }

        const data = $(e.target)
          .serializeArray()
          .reduce(function (result, item) {
            result[item.name] = item.value

            return result
          }, {})

        window._talkableq = window._talkableq || []
        _talkableq.push(['init', { site_id: 'talkable-raf' }])

        window._talkableq.push([
          'register_event',
          {
            event: {
              event_category: 'lead_form_submission',
              event_number: e.timeStamp,
            },
            customer: {
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
            },
            custom_properties: {
              company: data.company,
              phone: data.phone,
              revenue: data.revenue,
              platform: data['00N1N00000PJ1RG'],
            },
          },
        ])
      },

      'ajax:success': function (e, { message }) {
        self.trackSubmission(message)
      },

      'ajax:error': function (e, data) {
        NotificationBar.show(
          _.get(data, 'responseJSON.message', defaultErrorMessage),
          'error',
          8000,
        )
      },
      'ajax:complete': function () {
        return typeof grecaptcha !== 'undefined' && grecaptcha !== null
          ? grecaptcha.reset()
          : undefined
      },
    })
  }
})((window.Apply = window.Apply || {}), jQuery)
