/**
 * @prettier
 */

;(function (Contact, $) {
  const self = Contact

  self.selector = $('.js-form')

  self.init = () => {
    Utils.trackGAEvent(
      'Sales Inquiry Forms',
      'Shown',
      self.selector.data('ga-event-label'),
    )
  }
})((window.Contact = window.Contact || {}), jQuery)
