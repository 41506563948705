/**
 * @prettier
 */

;(function (Mobile, $) {
  const self = Mobile

  const initSliderRewards = function () {
    let n = 1
    let timer = 0

    $('.js-rewards-slider-content').eq(0).addClass('active')
    $('.js-rewards-slider-list .progress-bar').eq(0).addClass('active')

    $('video').each(function () {
      if ($(this).parent().parent().parent().hasClass('active')) {
        this.load()
        setTimeout(() => {
          this.play()
        }, timer)
        timer += 6000
      }
    })

    const updateSlide = function () {
      $('.js-rewards-slider-list').removeClass('active')
      $('.js-rewards-slider-content').removeClass('active')
      $('.js-rewards-slider-content').removeClass('active')
      $('.js-rewards-slider-list .progress-bar').removeClass('active')
      $('.js-rewards-slider-list').eq(n).addClass('active')
      $('.js-rewards-slider-content').eq(n).addClass('active')
      $('.js-rewards-slider-list .progress-bar').eq(n).addClass('active')

      if (n === $('.js-rewards-slider-list').length - 1) {
        n = 0
      } else {
        n++
      }

      timer = 0
      $('video').each(function () {
        if ($(this).parent().parent().parent().hasClass('active')) {
          this.load()
          setTimeout(() => {
            this.play()
          }, timer)
          timer += 6000
        }
      })
    }

    let intervalNumber = setInterval(() => {
      updateSlide()
    }, 18_000)

    $('.js-rewards-slider-list').on('click', function () {
      clearInterval(intervalNumber)
      n = $('.js-rewards-slider-list').index($(this))
      updateSlide()
      intervalNumber = setInterval(() => {
        updateSlide()
      }, 18_000)
    })
  }

  const initSlider = function () {
    let n = 1
    let stopSlider = false
    const updateSlide = function () {
      $('.js-slider-list').removeClass('active')
      $('.js-slider-img').removeClass('active')
      $('.js-slider-text').removeClass('active')
      $('.js-slider-list').eq(n).addClass('active')
      $('.js-slider-img').eq(n).addClass('active')
      $('.js-slider-text').eq(n).addClass('active')

      if (n === $('.js-slider-list').length - 1) {
        n = 0
      } else {
        n++
      }
    }

    let intervalNumber = setInterval(() => {
      updateSlide()
    }, 5000)

    $('.js-slider-list').on('click', function () {
      stopSlider = true
      clearInterval(intervalNumber)
      n = $('.js-slider-list').index($(this))
      updateSlide()
    })

    $('.js-slider-img').mouseenter(() => {
      clearInterval(intervalNumber)
    })

    $('.js-slider-img').mouseleave(function () {
      if (!stopSlider) {
        intervalNumber = setInterval(() => {
          updateSlide()
        }, 5000)
      }
    })
  }

  const showContent = function () {
    initSlider()
    initSliderRewards()
    $('.js-app').addClass('is-shown')
    setTimeout(() => $('.js-header').removeClass('is-hidden'), 2000)
    $('.js-hero-slide').eq(0).addClass('is-active')
    $('.js-hero-decor').eq(0).addClass('is-active')
  }

  self.init = () => showContent()
})((window.Mobile = window.Mobile || {}), jQuery)
