/**
 * @prettier
 */

;(function () {
  const element = '.js-alert'
  const content = '.js-alert-content'
  const close = '.js-alert-close'

  const self = (window.NotificationBar = {})
  const notificationBarHtml = `
    <div class="alert js-alert">
      <div class="alert-container js-alert-content"></div>
      <div class="alert-close js-alert-close">&times;</div>
    </div>`

  self.show = function (text, state, delay) {
    $('body').append(notificationBarHtml)

    $(close).on('click', function () {
      self.hide()
    })

    if (state) $(element).addClass('is-' + state + ' ' + 'alert-' + 'state')

    $(content).html(text)
    $(element).addClass('is-shown')

    self.timer = setTimeout(function () {
      self.hide()
    }, delay || 3000)
  }

  self.hide = function () {
    $(element).remove()
  }
})()
