/**
 * @prettier
 */

;(function (Header, $) {
  let stickHeader
  const self = Header

  self.init = function () {
    stickHeader()
    burger()

    return $(window).scroll(() => stickHeader())
  }

  const toggleHeaderMenu = function () {
    $('html, body').toggleClass('is-cut')
    $('.js-header-menu-mobile').toggleClass('is-open')
  }

  const burger = function () {
    $('.js-header-burger').on('click', function (e) {
      $(this).toggleClass('is-open')
      toggleHeaderMenu()
      e.preventDefault()
    })

    $('body').on('click', '.js-header-nav.is-open a', () => {
      $('.js-header-burger').toggleClass('is-open')
      toggleHeaderMenu()
    })
  }

  stickHeader = function () {
    if (Utils.currentScroll > 12) {
      $('.js-header').addClass('is-sticky')
    } else {
      $('.js-header').removeClass('is-sticky')
    }
  }
})((window.Header = window.Header || {}), jQuery)
